// Prod
// export const baseApiUrl = 'https://webservice.granshopping.com.br/api';
// export const s3Url = 'https://granshopping.s3.amazonaws.com/products/';
// export const environment = 'production';
// export const myUrl = 'https://granshopping.com.br';

// Dev
export const baseApiUrl = 'https://shoptest.gransys.com.br/api';
export const s3Url = 'https://grancommtest.s3.amazonaws.com/products/';
export const environment = 'dev';
export const myUrl = 'https://granshop.gransys.com.br';


// // Local
// export const baseApiUrl = 'http://127.0.0.1:8000/api';
// export const s3Url = "https://grancommtest.s3.amazonaws.com/products/";
// export const environment = 'dev';
// export const myUrl = 'http://localhost:8080';
export const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxNyIsImp0aSI6ImM4NWViM2M0NGRhYjA1NjNlNTVlYWViZTIzNzRjZGJhNzNjM2ZhNTY4ZGQ1MjFhOTQ0Y2I3NzNmNGIwZmMwN2YxMTk2ZjhjM2MwOGQ3NTBiIiwiaWF0IjoxNzI5NTI5NTc1LCJuYmYiOjE3Mjk1Mjk1NzUsImV4cCI6MTc2MTA2NTU3NSwic3ViIjoiMjc1Iiwic2NvcGVzIjpbXX0.D1C_Y4PLxfELLf5WCm5BXDo_dw6sK1kyDfLTyYBqXGlm0ST8ckRluQa-RoEGzdawePNwx_fy_yXECF_e-FnNgvBRcxiYOPpCmi8yRnJQ42-BjzZ424siEfNl1hd3teTWHuX-I4d2EyPjFc4CaPIGWUIoak2jPQO9PDY4wXJDEMIcc-6BqeuGTJm1EV76vMjj822Qn-T77O-ETrqnraWhY92eyFjb_1kIshFQRINMolBSKCnJLkc4ri5ygGLH4wf8L6AgynpsJG7Ka1W-ZQhHGJwb_dQ9GUY4OhXJHJEukcOK4_bDW687q10RWPv5PHwm8iSzdYc8aXF_aRopFf8ADY_OAVqQCX5tyw1iXFPdPA_vimLOji-PBMI1bzaVv71TIt3gEQPgs4tttWCh3qu6XG-P260rgFBoY8gkEMj6lEgnLl6wNMmIVN8FaBhiM4jtF1wolE_0qEQRBIDBZhPNFPcqJbXUhdX2Oz1TiITS_HykEtTrXKhtdBNekT-T9vvtriSMiXdtOKh-u02o0tM7WKqB73NC4xJmlE_m8V3jjXdmdw6EgwoBz8bx9TjKgQ-vPg98ybDE6i0UO-yCoySNpHG4pDPp0v2t1fmo0kYrFyGjFdxMaxdCbo-jYOpycfOTUwpO_RftXADRHbUER4m9AdQw5ZDeQWHZ7175C6YhHZA';


export const publicAppKey = "950574f9-b674-47d1-8a4c-a1a639b02249"
// export const publicAppKey = "dc6c9f5d-c71f-4670-929e-b95896584420"
export const catchError = function (e) {
    alert(`Algo deu errado tente novamente!!! ... ${e}`)
};


export default { baseApiUrl, catchError };